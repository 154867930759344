import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"
import Congress from "../../../components/congress"
import CaroselSlide from "../../../components/carosel-slide"

const CongressActivities = () => (
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
	    <script src={'/js/congress-activities.js'} type="text/javascript" />
    </Helmet>
    <Seo title="Congress Activities" description="Join Arena at upcoming congresses in 2022, or learn about our involvement in past congresses" />
    <header className="headline">
	<img src={"/images/our-pipeline-gastroenterology.svg"} alt="Our pipeline gastroenterology" />
	</header>
	<section className="wrap"><h2>We’re pursuing every opportunity to develop and bring our investigational drugs <nobr>to patients</nobr></h2></section>
	<section className="spacer"></section>
	<section className="wrap congress-activities"><header><h1>CONGRESS <span>ACTIVITIES</span></h1></header>
	<div className="flex">
	<div>
		<img id="congress-activities" src={"/images/congress-activities.svg"} alt="ECCO'22 17th Congress of ECCO February 16-19, 2022 Virtual" />
	</div><div><a href="https://www.ecco-ibd.eu/ecco22.html" target="_blank" ><img src={"/images/visit-site.svg"} alt="Visit Site" width="123" height="36" /></a></div></div>
	</section> <section className="wrap symposium-highlight"><div className="symposium-left"><StaticImage
      src="../../../images/symposium-thumbnail.png"
      width={300}
      quality={60}
      formats={["auto", "webp", "avif"]}
      alt="From theory to practice"
     
    /></div><div className="symposium-right"><h5>Please join us for the Arena satellite symposium</h5><strong>From theory to practice: Adapting guidance to meet patient-specific needs and goals in 
UC&nbsp;management</strong><p>The symposium will stream live on the ECCO 2022 virtual meeting platform on Friday, February 18, at <nobr>12:40 - 13:20</nobr> Central European Time (CET) in Virtual Room 9. The recorded session will be available on demand on the ECCO 2022 platform following the live session. </p></div> </section>
	<section className="wrap">
	<section className="tabContent treatment-guidelines">
	  <div className="content selected blue-title-without-link">
		<div className="line">
			<h5>Accepted Abstract</h5>
			<p>A Phase 1 Study Evaluating the Bioequivalence of the Proposed Commercial and Clinical Formulations of Etrasimod 2mg, and the Effect of Food on the Pharmacokinetics of the Proposed Commercial Formulation in Healthy Volunteers</p>
			<div><hr /></div>
			<span></span>
		</div>
		
		<div className="line"><h5>Accepted Abstract</h5><p>A Phase 1 Study Evaluating the Effect of Coadministration of Etrasimod on the Pharmacokinetics and Pharmacodynamics of a Monophasic Oral Contraceptive in Healthy Female Volunteers</p><div><hr /></div><span></span></div></div>
	</section>
	</section> <section className="spacer"></section>
	<section className="wrap">
	<header className="orange rule"><h1  id="upcoming-congresses">UPCOMING <span>CONGRESSES</span></h1></header>
	<div className="caroselHolder">
	<img src={"/images/back.svg"}  id="caroselBack" alt="<" className="disabled" />
	<div className="carosel state0">
	<div className="slideHolder">
	<CaroselSlide 
		congress="Digestive Disease Week (DDW) 2022"
		date="May 21-24, 2022"
    	location ="San Diego, CA"
		url = "https://www.asge.org/home/education-meetings/event-detail/2022/05/21/default-calendar/digestive-disease-week-2022"
	/>
	<CaroselSlide 
		congress="British Society of Gastroenterology (BSG) 2022 Annual Meeting "
		date="June 20-23, 2022"
    	location ="Birmingham, UK"
		url = "https://live.bsg.org.uk/"
	/>
	<CaroselSlide 
		congress="Viszeralmedizin 2022"
		date="Sept 12-17, 2022"
    	location ="Hamburg, Germany"
		url = "https://www.viszeralmedizin.com/"
	/>
	<CaroselSlide 
		congress="United European Gastroenterology (UEG) Week 2022"
		date="Oct 8-11, 2022"
    	location ="Vienna & Online"
		url = "https://ueg.eu/p/202/"
	/>
	<CaroselSlide 
		congress="American College of Gastroenterology (ACG) 2022"
		date="Oct 21-26, 2022"
    	location ="Charlotte, NC"
		 url="https://acgmeetings.gi.org"
	/>
	<CaroselSlide 
		congress="Advances in Inflammatory Bowel Diseases (AIBD) 2022"
		date="December 5-7, 2022"
    	location ="Orlando, FL"
		url="https://www.advancesinibd.com"
	/>
	
	</div>
	
	<div className="indicatorDots">
		<div className="dot"></div>
		<div className="dot"></div>
		<div className="dot"></div>
		<div className="dot"></div>
		<div className="dot"></div>
		<div className="dot"></div>
	</div>
    
	
	</div>
	<img src={"/images/forward.svg"} id="caroselForward" alt=">" />
	</div>
	<section className="spacer"></section>
		



	<section className="wrap"  id="connect">
	<header className="orange rule"><h1 id="arena-at-past-congresses">ARENA AT <span>PAST CONGRESSES</span></h1></header>

		<div class="full inline"><div className="tabHolder col2"><div className="tab selected"><a href="#tab0"><strong>American College of Gastroenterology Annual Scientific Meeting</strong><br />
October 25-27, 2021</a></div><div className="tab"><a href="#tab1"><strong>United European Gastroenterology Week Virtual</strong><br/>
October 3-5, 2021</a></div></div></div>
	<div class="full inline"><section className="tabContent"><div className="content rows selected congresses">
	

<Congress 
name="P0524" 
description={<>Pharmacokinetics (PK), Safety, and Tolerability of Etrasimod (APD334) in Participants With Mild, Moderate, or Severe Hepatic Impairment: A <nobr>Single-dose</nobr>, Open-label, <nobr>Parallel-group</nobr> Study</>}
link="https://www.eventscribe.net/2021/ACGPosters/fsPopup.asp?efp=SEhPSVVZQkoxNTA4Nw%20&PosterID=419218%20&rnd=2.624893E-02&mode=posterinfo" 
/>

<Congress 
    name="P0518" 
    description="Etrasimod Improves Quality of Life in Adults With Moderate-to-Severe Ulcerative Colitis: Results From the Phase 2 OASIS Trial and Open-label Extension" 
    link="https://www.eventscribe.net/2021/ACGPosters/fsPopup.asp?efp=SEhPSVVZQkoxNTA4Nw%20&PosterID=419224%20&rnd=0.4696151&mode=posterinfo" 
    />

<Congress 
    name="P2628" 
    description="Real-world Treatment (Tx) Patterns and Healthcare Costs Among US Commercially Insured Adults Initiating Biologics for Ulcerative Colitis (UC)" 
    link="https://www.eventscribe.net/2021/ACGPosters/fsPopup.asp?efp=SEhPSVVZQkoxNTA4Nw%20&PosterID=417466%20&rnd=0.7671512&mode=posterinfo" 
    />
	
	
	</div><div className="content rows">
	
<Congress 
    name="P0390" 
    description={<>Pharmacokinetics, Safety, and Tolerability of Etrasimod (APD334) in Subjects With Mild, Moderate, or Severe Hepatic Impairment: A Single-dose, Open-label, <nobr>Parallel-group</nobr> Study</>} 
    link="https://programme.ueg.eu/week2021/#/details/presentations/480" 
    />

<Congress 
    name="P0406" 
    description="Etrasimod Improves Quality of Life in Adults With Moderate-to-Severe Ulcerative Colitis: Results From the Phase 2 OASIS Trial and Open-label Extension" 
    link="https://programme.ueg.eu/week2021/#/details/presentations/643" 
    />

<Congress 
    name="P0428" 
    description="Real-world Treatment Patterns and Healthcare Costs Among US Commercially Insured Adults Initiating Biologics for Ulcerative Colitis" 
    link="https://programme.ueg.eu/week2021/#/details/presentations/828" 
    />

<Congress 
    name="OP115" 
    description="Efficacy and Safety of Olorinab, a Peripherally Acting, Highly Selective, Full Agonist of Cannabinoid Receptor 2, for the Treatment of Abdominal Pain in Patients With Irritable Bowel Syndrome: Results From a Phase 2b Randomized Study" 
    link="https://programme.ueg.eu/week2021/#/details/presentations/1254" 
    />

</div>	



</section></div>
	<section class="congress-footer">
	<p>Additional congress presentations can be found within the publications archive <a href={"/gi/our-pipeline/publications-archive/"}>here</a>.</p>
	</section>
	</section>
	</section>
    <section className="spacer end"></section>
	<footer className="definitions">ECCO=European Crohn's and Colitis Organisation; IBD=inflammatory bowel disease.<br/><span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span></footer>
<div className="overlay video closed"><div className="shadow"></div><div className="box"><iframe src="https://player.vimeo.com/video/668657329?h=0c76e05c42" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div></div>
  </Layout>
)

export default CongressActivities
