import * as React from "react"

const Congress = ( {name, description, link} ) => {
  return (
	 <>
     <a href={link} target="_blank" rel="noreferrer">
      <div className="left"><h5>{name}</h5>
		<p>{description}</p></div>
		<div className="right"><img src={"/images/view-button.svg"} alt="View"  width="170" height="35" /></div>
		
	  </a>
	  </>
  )
}



export default Congress
