import * as React from "react"

const CaroselSlide = ( {congress, date, location, url} ) => {
  return (
	  <div className="slide"><h5>{congress}</h5><hr /><span className="date">{date}<br />
{location}
</span>	  
{URLImage(url)}
</div>
  )
}

function URLImage( theURL)
{
	if(theURL)
		{
			return <a href={theURL}><img src={"/images/visit-site.svg"} alt="Visit Site" target="_blank" /></a>
		}
	else
		return false;
}


export default CaroselSlide
